import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    document.addEventListener('turbo:before-cache', this.close);
    document.body.classList.add('modal-open');
  }

  disconnect() {
    document.body.classList.remove('modal-open');
    document.removeEventListener('turbo:before-cache', this.close);
  }

  checkContainerAndClose = (ev) => {
    if (ev.target === this.containerTarget) {
      this._removeModal();
    }
  };

  close = () => {
    this._removeModal();
  };

  _removeModal = () => {
    this.element.parentNode.removeChild(this.element);
  };
}
